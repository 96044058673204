import InputSelect from "components/common/form/InputSelect/InputSelect";
import { getLanguageSelectOption } from "app/bigcommerce/helpers/BcConfigHelper";
import { useTranslation } from "react-i18next";

const LanguageSelect = ({ value, onChange }) => {
  const { t } = useTranslation();

  let options = getLanguageSelectOption();

  options = (options.map((item)=>{
    return {
      value: item.value,
      label: t(`country.${item.value}`)
    }
  }))

  return (
    <InputSelect
      options={options}
      value={value}
      onChange={onChange}
    />
  );
};

export default LanguageSelect;
