import { RouteData } from "app/types/types";
import { WordpressTemplateEnum } from "app/wordpress/components/WordpressTemplates";
import BlogPage from "pages/blog/BlogLandingPage";
import BlogPost from "pages/blog/BlogPost";
import AboutUs from "pages/company/AboutUs";
import ApplicationFormPage from "pages/company/ApplicationFormPage";
import Awards from "pages/company/Awards";
import Careers from "pages/company/Careers";
import HrAdvice from "pages/company/HrPages/HrAdvice";
import HrDetail from "pages/company/HrPages/HrDetail";
import JobDescription from "pages/company/JobDescription";
import LoyaltyPage from "pages/company/LoyaltyPage";
import SocialResponsibility from "pages/company/SocialResponsibilitiesPages/SocialResponsibility";
import SocialResponsibilityDetailPage from "pages/company/SocialResponsibilitiesPages/SocialResponsibilityDetail";
import StaffStory from "pages/company/StaffStory";
import Opportunities from "pages/company/opportunities/Opportunities";
import GenericErrorPage from "pages/error/GenericErrorPage";
import LandingPage from "pages/landingPage/LandingPage";
import FullWidthPage from "pages/supportingPages/FullWidth/fullWidthPage";
import WSPayPaymentConfirmationPage from "pages/supportingPages/WSPay/WSPayPaymentConfirmationPage";
import ContactUsPage from "pages/supportingPages/customerService/ContactUsPage/ContactUsPage";
import DeliveryPage from "pages/supportingPages/customerService/DeliveryPage/DeliveryPage";
import FaqsPage from "pages/supportingPages/customerService/FaqsPage/FaqsPage";
import StoresPage from "pages/supportingPages/customerService/StoresPage/StoresPage";
import LegalPage from "pages/supportingPages/legal/LegalPage";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route } from "react-router-dom";
import { useDispatch } from "redux/hooks";
import { useSelector } from "redux/hooks";
import { setWordpressBlogTitle } from "redux/slices/settingsSlice";

const getRouteElement = (templateEnum: WordpressTemplateEnum) => {
	switch (templateEnum) {
		case WordpressTemplateEnum.ApplicationPage:
			return undefined;
		case WordpressTemplateEnum.AwardsAndPrizes:
			return <Awards />;
		case WordpressTemplateEnum.BlogHomepage:
			return <BlogPage />;
		case WordpressTemplateEnum.Careers:
			return <Careers />;
		case WordpressTemplateEnum.ContactUs:
			return <ContactUsPage />;
		case WordpressTemplateEnum.CoverTemplate:
			return undefined;
		case WordpressTemplateEnum.CurrentOpportunities:
			return <Opportunities />;
		case WordpressTemplateEnum.CustomerServiceContentPage:
			return <DeliveryPage />;
		case WordpressTemplateEnum.FAQS:
			return <FaqsPage />;
		case WordpressTemplateEnum.Homepage:
			return <LandingPage />;
		case WordpressTemplateEnum.HrAdvice:
			return <HrAdvice />;
		case WordpressTemplateEnum.HrAdviceDetail:
			return <HrDetail />;
		case WordpressTemplateEnum.JobApplicationPage:
			return <ApplicationFormPage />;
		case WordpressTemplateEnum.Legal:
			return <LegalPage />;
		case WordpressTemplateEnum.FullWidth:
			return <FullWidthPage />;
			case WordpressTemplateEnum.WSPayPaymentConfirmationPage:
			return <WSPayPaymentConfirmationPage />;
		case WordpressTemplateEnum.AboutUs:
			return <AboutUs />;
		case WordpressTemplateEnum.LoyaltyPage:
			return <LoyaltyPage />;
		case WordpressTemplateEnum.OrderConfirmation:
			return undefined;
		case WordpressTemplateEnum.SocialResponsibility:
			return <SocialResponsibility />;
		case WordpressTemplateEnum.SocialResponsibilityDetail:
			return <SocialResponsibilityDetailPage />;
		case WordpressTemplateEnum.Stores:
			return <StoresPage />;
	}
};

const WordpressRoutes = () => {
	const { t } = useTranslation();
	const wordpressTemplates = useSelector(
		(state) => state.settings.wordpressTemplates,
	);
	const dispatch = useDispatch();

	const [routes, setRoutes] = useState<JSX.Element[]>([]);

	useEffect(() => {
		const newRoutes = [] as RouteData[];

		wordpressTemplates.forEach((template) => {
			const element = getRouteElement(template.template);

			if (element) {
				let path = template.uri;

				if (template.template === WordpressTemplateEnum.Homepage) {
					path = path.replace("/homepage", "");
				}

				newRoutes.push({
					path,
					element,
				});
			}
		});

		const blogsHomepage = wordpressTemplates.find(
			(template) => template.template === WordpressTemplateEnum.BlogHomepage,
		);

		if (blogsHomepage) {
			dispatch(setWordpressBlogTitle(blogsHomepage.title));

			newRoutes.push({
				path: `${blogsHomepage.uri}/:post_slug`,
				element: <BlogPost />,
			});
		}

		const currentOpportunities = wordpressTemplates.find(
			(template) =>
				template.template === WordpressTemplateEnum.CurrentOpportunities,
		);

		if (currentOpportunities) {
			newRoutes.push({
				path: `${currentOpportunities.uri}:jobId`,
				element: <JobDescription />,
			});
		}

		newRoutes.push({
			path: `${t("url.colleague")}/:colleagueName`,
			element: <StaffStory />,
		});

		newRoutes.push({
			path: "*",
			element: <GenericErrorPage />,
		});

		setRoutes(
			newRoutes.map((route) => (
				<Route key={route.path} path={route.path} element={route.element} />
			)),
		);
	}, [wordpressTemplates, dispatch, t]);

	return routes;
};

export default WordpressRoutes;
