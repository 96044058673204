import { FunctionComponent, useState } from "react";
import IconLanguage from "components/common/ui/Icons/IconLanguage";
import LanguageModal from "components/common/ui/Modal/LanguageModal";
import LanguageSelect from "./LanguageSelect";
import { useDispatch } from "redux/hooks";
import { setPreferredLocation } from "redux/location/locationSlice";
import Button from "components/common/ui/Button/Button";
import { ModalTitle } from "./Styled";
import styled from "styled-components";
import { aboveMedium } from "utilities/variables/media";
import { useTranslation } from "react-i18next";
import { logout } from "redux/auth/authSlice";

interface LanguageSwitcherModalProps {
	storeCode: string | undefined;
	isOpen: boolean;
	onClose: Function;
}

const StyledModal = styled(LanguageModal)`
    width: calc(100%);
    max-width: 328px;
    top: 30px;
	right: 1%;    
    position: fixed;
    padding-top: 46px;
    padding-bottom: 32px !important;

    /* Explicitly style the close button */
    z-index: 1;
    }

    @media ${aboveMedium} {
        transform: unset;
        right: 20px;
        top: 30px;
    }
`;

const LanguageSwitcherModal: FunctionComponent<LanguageSwitcherModalProps> = ({
	storeCode,
	onClose,
	...props
}) => {
	const [selectedValue, setSelectedValue] = useState(storeCode);
	const dispatch = useDispatch();

	const changeLanguage = async () => {
		if (selectedValue !== storeCode) {
			Promise.resolve(await dispatch(setPreferredLocation(selectedValue))).then(
				async () => {
					await dispatch(logout());

					window.location.href = `/${selectedValue}`;
				},
			);
		}

		onClose();
	};

	const { t } = useTranslation();

	return (
		<StyledModal
			onClose={() => onClose()}
			title={
				<ModalTitle>
					<IconLanguage variant="gray" />
					{t("languageSwitcher.location")}
				</ModalTitle>
			}
			options={{
				portalId: "portal__modal",
				showCloseButton: true,
			}}
		>
			<LanguageSelect
				value={selectedValue}
				onChange={(value: string) => setSelectedValue(value)}
			/>
			<Button
				margin={{
					mt: "16px",
				}}
				variant="primary_01"
				fullWidth
				onClick={changeLanguage}
			>
				{t("languageSwitcher.submit")}
			</Button>
		</StyledModal>
	);
};

export default LanguageSwitcherModal;
