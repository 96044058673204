import { useEffect } from "react";
import FullWidthPage from "../FullWidth/fullWidthPage";

const WSPayPaymentConfirmationPage = () => {
    useEffect(()=>{
       
		const params = new URLSearchParams(window.location.search);
		const success = params.get("Success");
		console.debug("Extracted 'Success' parameter from URL:", success);

	
		if (success === "1") {
			console.debug("Preparing to send success message to parent window.");
		  window.parent.postMessage({ type: "TRANSACTION_SUCCESS", success: true }, "*");
		} else {
			console.debug("Preparing to send failure message to parent window.");
			window.parent.postMessage({ type: "TRANSACTION_SUCCESS", success: false }, "*");
		}
    },[])

	return (
		
        <FullWidthPage />
	);
};
export default WSPayPaymentConfirmationPage