import styled from "styled-components/macro";
import { CustomiseComponent } from "ts/interfaces";

export const Container = styled.div``;

export const ModalContainer = styled.div<CustomiseComponent>`
	position: absolute;
	width: 328px;

	width: 100%;
	max-width: inherit;
	padding: 16px;
	background-color: white;
	border-radius: 8px;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
	.content {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 0px 24px;
	}
	.btn_modal-close {
		position: absolute;
		font-size: 24px;
		top: 17px;
		right: 17px;
		z-index: 10;
	}
	${(props) => props.styles}
`;

export const ModalHeader = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	margin-bottom: 6px;

	.btn_modal-close {
		position: absolute;
		font-size: 24px;
		margin-left: auto;
		justify-content: flex-end;
	}
`;

export const ModalBody = styled.div``;
