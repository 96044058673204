import styled from "styled-components";
import { IonText } from "@ionic/react";

import { dark } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { aboveSmall } from "utilities/variables/media";

export const StyledWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 20px;
	background-color: ${dark};
`;

export const StyledCopyrigthWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 20px;
	background-color: ${dark};
`;

export const StyledColumn = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	@media ${aboveSmall} {
		flex-direction: row;
		gap: 4px;
	}
`;

export const StyledText = styled(IonText)`
	font: normal 14px/17px ${Roboto};
	letter-spacing: 0px;
	color: #fff;
`;

export const StyledLanguageSwitcherWrapper = styled.div`
	i,
	span {
		color: #fff;
	}

	span {
		font: normal 12px/15px ${Roboto};
		text-transform: uppercase;
	}
`;
