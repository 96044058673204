import Page from "components/common/ui/Page/Page";
import { PageType } from "ts/enums";
import { embedCheckout } from "@bigcommerce/checkout-sdk";
import { FunctionComponent, useEffect, useState } from "react";
import useCart from "hooks/cart/useCart";
import { useLazyGetCartQuery } from "app/api/cart/cartApi";
import LoadingSpinner from "components/common/ui/LoadingSpinner/LoadingSpinner";
import useLocaleNavigate from "hooks/reactRouterWrappers/useLocaleNavigate";
import { useTranslation } from "react-i18next";
import PathTranslationKey from "utilities/paths";
import Cookies from "js-cookie";
import { COOKIE_CART_ID } from "utilities/consts";
import { isAndroid, isIOS } from "react-device-detect";

import { dark } from "utilities/variables/colors";
import styled from "styled-components/macro";
import { remCalc } from "utilities/styles";
import Link from "components/common/ui/Link/Link";
import { useDispatch } from "react-redux";

const Title = styled.h2`
	color: ${dark};
	font-size: ${remCalc(20)};
	line-height: ${remCalc(24)};
	font-weight: bold;
	text-align: center;
	margin: 0;
	margin-top: ${remCalc(16)};
	padding: 0 16px;
`;

const SubTitle = styled.h4`
	color: ${dark};
	font-size: ${remCalc(14)};
	line-height: ${remCalc(17)};
	font-weight: normal;
	text-align: center;
	margin: 0;
	margin-bottom: ${remCalc(16)};
	padding: 0 16px;

	a {
		text-decoration: underline;
	}
`;

const Checkout: FunctionComponent = () => {
	const { cart, cartExists, deleteCart } = useCart();
	const { t } = useTranslation();
	const navigate = useLocaleNavigate();

	const [getCart, getCartResult] = useLazyGetCartQuery();

	const [embeddedLoaded, setEmbeddedLoaded] = useState(false);

	useEffect(() => {
		if (cart) {
			getCart(cart.id)
				.unwrap()
				.then((res) => {
					let url = res.redirect_urls.embedded_checkout_url

					if (res.embed_checkout_jwt) {
						url = `https://${new URL(res.redirect_urls.checkout_url).hostname}/login/token/${res.embed_checkout_jwt}`
					}

					if (isIOS) {
						return window.location.href = url;
					}

					if (url)
						embedCheckout({
							containerId: "checkout-app",
							url: String(url),
							onSignOut: () => navigate(t(PathTranslationKey.SIGN_OUT)),
							onComplete: () => deleteCart(),
							onLoad: () => setEmbeddedLoaded(true)
						});
				});

			return;
		}
		if (!embeddedLoaded && !cartExists()) {
			navigate(t(PathTranslationKey.CART));
		}
	}, [cart]);

	return (
		<Page pageType={PageType.DEFAULT}>
			<div id="checkout-app"></div>
			{(getCartResult.isLoading || getCartResult.isFetching) ? (
				<LoadingSpinner />
				) : (
				(isIOS) ? (
					<>
						<Title>{t('checkout.title')}</Title>
						<SubTitle>
							<Link to={`${String(t(PathTranslationKey.CART))}`}>
								{t('checkout.subtitle')}
							</Link>
						</SubTitle>
					</>
				) : null
			) }
		</Page>
	);
};

export default Checkout;
