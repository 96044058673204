import Price from "components/common/ui/Price/Price";
import Skeleton from "components/common/ui/Skeleton/Skeleton";
import Typography from "components/common/ui/Typography/Typography";
import { Fragment } from "react";
import { useSelector } from "redux/hooks";
import { Price as PriceType } from "ts/types";
import {
	StyledTypographyPrice,
} from "./PdpBasicInfo.Styled";

type Props = {
	loading?: boolean;
	name: string;
	basePrice?: PriceType;
	price?: PriceType;
	salePrice?: PriceType;
	croatiaMiddlePrice?: string;
};

const PdpBasicInfo = ({ loading, name, basePrice, price, salePrice, croatiaMiddlePrice }: Props) => {

	const hasSalePrice = (
		salePrice?.value
		&& (
			salePrice?.value !== price?.value ||
			salePrice?.value !== basePrice?.value
		)
	) || false;

	return (
		<Fragment>
			<Typography variant="heading" size="small">
				{loading ? <Skeleton width={250} /> : name}
			</Typography>
			<div className="price-container">
				{hasSalePrice ? (
					<StyledTypographyPrice className="price price--sale" variant="heading" size="small">
						<Price
							price={salePrice?.value || 0}
						/>
					</StyledTypographyPrice>
				) : null}
				<StyledTypographyPrice className={`price${hasSalePrice ? ' price--hasSale' : ''}`} variant="heading" size="small">
					<Price
						price={hasSalePrice ? (basePrice?.value || 0) : (price?.value || 0)}
					/>
				</StyledTypographyPrice>
			</div>
			{ (croatiaMiddlePrice && (parseFloat(croatiaMiddlePrice) !== 0)) ? (
				hasSalePrice ? (
					<StyledTypographyPrice className="price price--middlePrice" variant="heading" size="small">
						<div className="price--middlePriceText">
							Najniža cijena u posljednjih 30 dana prije sniženja:
						</div>
						<Price
							price={parseFloat(croatiaMiddlePrice.replace(',', '.')) || 0}
						/>
					</StyledTypographyPrice>
				) : null) : null}
		</Fragment>
	);
};

export default PdpBasicInfo;
