// ionic imports
import { IonContent } from "@ionic/react";

// component imports
import { bigCommerceMenuDataApi } from "app/api/sidemenuDataApi";
import { wordPressApi } from "app/api/wordpress/wordPressApi";
import useDataLayer from "datalayer/useDataLayer";
import useLoginState from "hooks/auth/useLoginState";
import {
	FunctionComponent,
	Suspense,
	lazy,
	useEffect,
	useRef,
	useState,
} from "react";
import { useLocation, useNavigate } from "react-router";
import uuid from "react-uuid";
import {
	setCanShowFooter,
	setShowAddToCartConformation,
} from "redux/UI/uiSlice";
import { useDispatch, useSelector } from "redux/hooks";
import { PageType } from "ts/enums";
import { BasePageTitle } from "utilities/variables/pageTitles";
import { PageContainer, Wrapper } from "./Page.styled";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Footer from "components/footer/Footer";
import AddToCartConformation from "components/cart/AddToCartConfirmation/AddToCartConformation";
import CartErrorModal from "components/cart/CartErrorModal/CartErrorModal";

interface PageProps {
	pageType: PageType;
	className?: any;
	title?: String;
	metaDescription?: string;
	children?: any;
	canShowFooter?: boolean;
	hideNavigation?: boolean;
}

let lastTimeout: any;

const Page: FunctionComponent<PageProps> = ({
	pageType,
	className,
	title,
	metaDescription,
	children,
	hideNavigation = false,
}) => {
	useLoginState();

	const dispatch = useDispatch();
	const { pageView } = useDataLayer();
	const location = useLocation();

	const [locationKey, setLocationKey] = useState("");
	const navigate = useNavigate();

	const [scrollDepthTriggered, setScrollDepthTriggered] = useState(false);
	const {
		bcStore,
		location: locationSlice,
		ui,
	} = useSelector((state) => state);

	const showConformation = ui.showAddToCartConformation;

	const prefetchBcData = bigCommerceMenuDataApi.usePrefetch("getMenuItems");
	const prefetchWpData = wordPressApi.usePrefetch("getMenuItemsBySlug");

	useEffect(() => {
		document.addEventListener("ionBackButton", (ev) => {
			navigate("..", { replace: false });
		});
	}, []);

	useEffect(() => {
		const ionAppElement = document.querySelector("ion-app");
		if (ionAppElement && hideNavigation) {
		  ionAppElement.style.display = "block";
		} else if (ionAppElement) {
		  console.error("Expected to find ion-app element. Navigation cannot be hidden."); 
		}
	  }, [hideNavigation]); 

	useEffect(() => {
		prefetchBcData({}, { force: true });
		prefetchWpData("header", { force: true });

		if (!sessionStorage.getItem("sessionId")) {
			sessionStorage.setItem("sessionId", uuid());
		}

		if (!sessionStorage.getItem("userId")) {
			sessionStorage.setItem("userId", uuid());
		}
	}, []);

	useEffect(() => {
		if (!bcStore.currencies.length || !locationSlice.preferredLocation) return;
		if (location.key !== locationKey) {
			pageView(pageType);

			setLocationKey(location.key);
		}

		if (!showConformation) return;
		dispatch(setShowAddToCartConformation(false));
	}, [location, locationSlice, bcStore]);

	useEffect(() => {
		const setPageTitleAndDescription = () => {
		  const newPageTitle = title
			? `${title} | ${BasePageTitle}`
			: `${BasePageTitle} | ${BasePageTitle}`;
	
		  if (document.title !== newPageTitle) {
			document.title = newPageTitle;
		  }
	
		  if (metaDescription) {
			let metaTag = document.querySelector('meta[name="description"]') as HTMLMetaElement;
			if (!metaTag) {
			  metaTag = document.createElement('meta') as HTMLMetaElement;
			  metaTag.name = 'description';
			  document.head.appendChild(metaTag);
			}
			metaTag.content = metaDescription;
		  }
		};
	
		setPageTitleAndDescription();
	  }, [title, metaDescription]);

	const ref = useRef<any>();

	return (
		<Suspense fallback={<LoadingSpinner />}>
			<IonContent
				ref={ref}
				id="ion-content"
				className={`${className} ${hideNavigation ? "no-navigation" : ""}`}
				scrollEvents
				onLoad={async () => {
					if (!ui.canShowFooter) {
						const ionContent = ref.current as HTMLIonContentElement;

						if (typeof ionContent === "undefined" || !ionContent) {
							return;
						}
						const scrollElement = await ionContent.getScrollElement();

						if (scrollElement.clientHeight === scrollElement.scrollHeight) {
							dispatch(setCanShowFooter(true));
						}
					}

					if (lastTimeout) clearTimeout(lastTimeout);

					lastTimeout = setTimeout(() => {
						const value = localStorage.getItem("clickedId");
						//TODO - refactor using debounce from lodash
						const el = document.getElementById(value || "");
						if (el) {
							el?.scrollIntoView({ behavior: "smooth" });
							localStorage.removeItem("clickedId");
						}
					}, 1000);
				}}
				onIonScroll={async ($event) => {
					if (scrollDepthTriggered) {
						return;
					}

					if ($event.target.localName != "ion-content") {
						// not sure if this is required, just playing it safe
						return;
					}

					const scrollElement = await $event.target.getScrollElement();
					// minus clientHeight because trigger is scrollTop
					// otherwise you hit the bottom of the page before
					// the top screen can get to 80% total document height
					const scrollHeight =
						scrollElement.scrollHeight - scrollElement.clientHeight;
					
					const currentScrollDepth = scrollElement.scrollTop;

					const targetPercent = 80;

					let triggerDepth = (scrollHeight / 100) * targetPercent;

					if (currentScrollDepth > triggerDepth) {
						setScrollDepthTriggered(true);
						dispatch(setCanShowFooter(true));
					}
				}}
			>
				<Wrapper>
					<PageContainer className="PageContainer">
						{bcStore.store && children}
					</PageContainer>
					{ui.canShowFooter && <Footer />}
				</Wrapper>

				{showConformation && <AddToCartConformation />}
				<CartErrorModal />
			</IonContent>
		</Suspense>
	);
};

export default Page;
