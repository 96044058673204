import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useBreakpoints from "hooks/useBreakpoints";
import {
	StyledColumn,
	StyledCopyrigthWrapper,
	StyledText,
} from "./Copyrigh.Styled";

const Copyright = () => {
	const { medium } = useBreakpoints();
	const { t } = useTranslation();

	const copyright = useMemo(() => {
		const year = new Date().getFullYear();
		return `©${year} www.monaonline.com.`;
	}, []);

	return (
		<StyledCopyrigthWrapper>
			<StyledColumn>
				<StyledText>{copyright}</StyledText>
				<StyledText>{t("allRightsReserved")}</StyledText>
			</StyledColumn>
			{medium && <div></div>}
		</StyledCopyrigthWrapper>
	);
};

export default Copyright;
