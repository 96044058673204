import { FunctionComponent, useMemo, useState } from "react";

import Carousel from "components/common/ui/Carousel/Carousel";
import MoreColors from "components/MoreColours/MoreColours";
import PdpBasicInfo from "components/pdp/PdpDetails/PdpBasicInfo/PdpBasicInfo";
import PdpDescription from "components/pdp/PdpDetails/PdpDescription/PdpDescription";
import PdpActions from "./PdpActions/PdpActions";
import PdpSizeActions from "./PdpSizeActions/PdpSizeActions";

import { StyledColumn } from "pages/pdp/ProductDetailPage.Styled";

import {
	BelowDetailsDivider,
	StyledProductDetailsDivider,
	StyledProductDetailsWrapper,
	StyledProductVariants,
	StyledSectionWrapper,
} from "./PdpDetails.Styled";

import {
	getCustomFieldsToRender,
	getSisterColourSKUs,
	getGroupId,
	getCroatiaMiddlePrice,
} from "app/utils/bigcommerceProductUtil";
import Skeleton from "components/common/ui/Skeleton/Skeleton";
import { Product, ProductCustomField, ProductVariant } from "ts/types";
import NotificationModal from "components/NotificationModal/NotificationModal";
interface PdpMainSectionProps {
	loading?: boolean;
	product?: Product;
}

const PdpDetailsSection: FunctionComponent<PdpMainSectionProps> = ({
	loading = false,
	product,
}) => {
	const [selectedVariant, setSelectedVariant] = useState<ProductVariant>();

	const handleVariantChange = (variant: ProductVariant) => {
		setSelectedVariant(variant);
	};

	const customFieldsToRender = useMemo(() => {
		if (!product) {
			return [] as ProductCustomField[];
		}

		return getCustomFieldsToRender(product);
	}, [product]);

	const groupId = useMemo(() => {
		if (!product) {
			return;
		}

		return getGroupId(product as Product);
	}, [product]);

	const sisterSkusCustomField = useMemo(() => {
		if (!product) {
			return;
		}

		return getSisterColourSKUs(product);
	}, [product]);

	const croatiaMiddlePrice = useMemo(() => {
		if (!product) {
			return;
		}

		return getCroatiaMiddlePrice(product);
	}, [product]);

	if (!loading && !product) {
		return null;
	}

	return (
		<StyledSectionWrapper className="PdpDetails">
			<Carousel loading={loading} images={product?.images || []} highRes />

			<StyledProductDetailsWrapper>
				<StyledColumn>
					<PdpBasicInfo
						loading={loading}
						name={product?.name || ""}
						basePrice={product?.basePrice}
						price={product?.price}
						salePrice={product?.salePrice}
						croatiaMiddlePrice={croatiaMiddlePrice?.value}
					/>
					{loading && (
						<Skeleton style={{ marginTop: "1rem" }} width={80} height={10} />
					)}
					{!loading && sisterSkusCustomField && (
						<MoreColors field={sisterSkusCustomField} />
					)}
				</StyledColumn>

				<StyledProductDetailsDivider />

				<StyledColumn>
					<PdpSizeActions loading={loading} product={product} />
				</StyledColumn>
				<StyledColumn>
					<StyledProductVariants
						loading={loading}
						variants={product?.variants || []}
						defaultValue={selectedVariant}
						onChange={handleVariantChange}
						controlled
						groupId={groupId?.value}
					/>
				</StyledColumn>
				<StyledColumn>
					<PdpActions
						loading={loading}
						onAddToCartSuccess={() => setSelectedVariant(undefined)}
						selectedVariant={selectedVariant}
						product={product}
					/>
				</StyledColumn>
				<StyledColumn>
					<PdpDescription
						loading={loading}
						category={product?.categories?.name || ""}
						customFields={customFieldsToRender || ""}
						description={product?.description || ""}
						sku={
							product
								? !selectedVariant
									? product.sku
									: selectedVariant.sku
								: ""
						}
					/>
				</StyledColumn>
			</StyledProductDetailsWrapper>
		</StyledSectionWrapper>
	);
};

export default PdpDetailsSection;
