import { useLazyGetProductBySkuQuery } from "app/api/product/productApi";
import Skeleton from "components/common/ui/Skeleton/Skeleton";
import Typography from "components/common/ui/Typography/Typography";
import { FunctionComponent, useEffect, useState } from "react";
import { Product } from "ts/types";
import { ItemWrapper, StyledLink } from "./SisterColourSKU.Styled";
import { useTranslation } from "react-i18next";
import PathTranslationKey from "utilities/paths";

interface Props {
	sku: string;
	indexSku?:number;
}

const SisterColourSKU: FunctionComponent<Props> = ({ sku, indexSku }) => {
	const { t } = useTranslation();
	const [product, setProduct] = useState<Product>();
	const [getProduct, getProductResult] = useLazyGetProductBySkuQuery();

	useEffect(() => {
		if (sku && !product && !getProductResult.isLoading) {
			getProduct(sku);
		}
	}, []);

	useEffect(() => {
		if (getProductResult.data) {
			if (getProductResult.data) setProduct(getProductResult.data);
		}
	});

	if (getProductResult.isUninitialized || getProductResult.isLoading) {
		if(indexSku === 0) {
			return (
				<li>
					<ItemWrapper>
						<Skeleton className="thumbnail" />
						<Skeleton count={2} height={14} className="text" />
					</ItemWrapper>
				</li>
			);
		}
	}

	if (!product) {
		return null;
	}

	const defaultImage =
		product.images.find((image) => image.isDefault) || product.images[0];


	if (!defaultImage || !product.categories?.name) {
		return null;
	}

	return (
		<li>
			<StyledLink
				to={`${t(PathTranslationKey.PRODUCT)}${product.path}`}
				state={{
					product,
				}}
			>
				<ItemWrapper>
					<img className="thumbnail" src={defaultImage?.url || ""} />
					<Typography variant="body" size="small">
						{product.name}
					</Typography>
				</ItemWrapper>
			</StyledLink>
		</li>
	);
};

export default SisterColourSKU;
