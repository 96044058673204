import Scrollable from "components/common/ui/Scrollable/Scrollable";
import { ProductCustomField } from "ts/types";
import SisterColourSKU from "./SisterColourSKU/SisterColourSKU";
import { VariantsList, VariantsWrapper } from "./SisterColourSKUs.Styled";
import { FunctionComponent } from "react";

interface SisterSKUsProps {
	field: ProductCustomField;
}

const SisterColourSKUs: FunctionComponent<SisterSKUsProps> = ({ field }) => {
	const skus = field.value.split(",");

	return (
		<VariantsWrapper>
			<Scrollable>
				<VariantsList>
					{skus.map((sku, indexSku) => (
						<SisterColourSKU key={sku} sku={sku} indexSku={indexSku} />
					))}
				</VariantsList>
			</Scrollable>
		</VariantsWrapper>
	);
};

export default SisterColourSKUs;
