import { Gender } from "components/pdp/PdpDetails/PdpSizeActions/PdpSizeGuide/PdpSizeGuide";
import { ValidWashingInstructionCodes } from "components/WashingInstructions/WashingInstrcutionCodes";
import { Product, ProductCustomField } from "ts/types";

export const getCustomField = (
	product: Product,
	customFieldName: string,
): ProductCustomField | undefined => {
	const targetName = customFieldName.trim().split(" ").join("").toLowerCase();

	const foundField =
		product?.customFields?.find((customField) => {
			const name = customField.name.trim().split(" ").join("").toLowerCase();
			return name === targetName;
		}) || undefined;

	return foundField;
};

const validGenderOptions = {
	male: ["muško", "male", "man", "men"],
	female: ["žensko", "female", "woman", "women"],
};

export const getGender = (product: Product) => {
	let gender =
		getCustomField(product, "erp.pol")?.value.toLowerCase() || undefined;

	if (!gender) {
		gender =
			getCustomField(product, "gender")?.value.toLowerCase() || undefined;
	}

	let validGender: Gender | undefined;

	if (gender) {
		if (validGenderOptions.male.includes(gender)) {
			validGender = Gender.MALE;
		} else if (validGenderOptions.female.includes(gender)) {
			validGender = Gender.FEMALE;
		}
	}

	return validGender;
};

export const filterCustomField = (
	customFields: ProductCustomField[],
	customFieldName: string,
): ProductCustomField | undefined => {
	const targetName = customFieldName.trim().split(" ").join("").toLowerCase();

	const foundField =
		customFields?.find((customField) => {
			const name = customField.name.trim().split(" ").join("").toLowerCase();
			return name === targetName;
		}) || undefined;

	return foundField;
};

export const getCompleteTheLook = (product: Product) => {
	return getCustomField(product, "complete the look");
};

export const getSisterColourSKUs = (product: Product) => {
	return getCustomField(product, "sister colour skus");
};

export const getGroupId = (product: Product) => {
	return getCustomField(product, "group id");
};

export const getCroatiaMiddlePrice = (product: Product) => {
	return getCustomField(product, "price_30");
};

export const filterWashingInstructions = (
	customFields: ProductCustomField[],
) => {
	return filterCustomField(customFields, "erp.washSimboli");
};

/**
 * Parse custom fields of a product and returns only those that need to be rendered
 *
 * @param product to parse
 * @returns Array contain custom fields to render
 */
export const getCustomFieldsToRender = (
	product: Product,
): ProductCustomField[] => {
	const fields = [] as ProductCustomField[];

	product.customFields?.forEach((customField) => {
		const name = customField.name.trim().split(" ").join("").toLowerCase();
		if (name.startsWith("erp.")) {
			fields.push(customField);
		}
	});

	return fields;
};

export const showSizeGuideCalc = (product: Product) => {
	/**
	 * Arrays of entity ids to match product options entity ids.
	 *
	 * If the product options entity id matches any of the below
	 * we don't want to show the size guide or size calculator.
	 */
	const dontShowEntityIds = [
		"300",
		"310",
		"330",
		"554",
		"610",
		"611",
		"612",
		"613",
		"614",
		"619",
		"620",
		"642",
		"644",
		"651",
		"652",
		"653",
		"661",
		"662",
		"663",
		"664",
		"665",
		"700",
		"710",
		"730",
		"750",
		"761",
		"762",
		"763",
		"764",
		"772",
		"773",
		"780",
		"791",
		"444",
		"445",
		"645"
	];

	const groupId = getGroupId(product)?.value || undefined;

	if (groupId) {
		return !dontShowEntityIds.includes(groupId);
	}

	return false;
};

/**
 * Check to see if the customer field has valid washing instructions
 *
 * @param field custom field to check
 * @returns true if valid
 */
export const washingInstructionsValid = (field: ProductCustomField) => {
	const codes = field.value.split(",").map((code) => code.trim());

	return ValidWashingInstructionCodes.some((code) => codes.includes(code));
};
